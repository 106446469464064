import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: `2.5rem`,
    paddingBottom: `2rem`,
    backgroundColor: `rgb(240,229,215)`,
  }
}))

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const TagRoute = ({data, pageContext}) => {
  const posts = data.allMarkdownRemark.edges
  const postLinks = posts.map((post) => (
    <ListItemLink href={`/latestAtLux${post.node.fields.slug}`} key={post.node.fields.slug}>
      <ListItemText primary={`${post.node.frontmatter.title} | ${post.node.frontmatter.date}`} />
    </ListItemLink>
  ))
  const tag = pageContext.tag
  const title = data.site.siteMetadata.title
  const totalCount = data.allMarkdownRemark.totalCount
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with “${tag}”`

  const classes = useStyles();

    return (
      <Layout>
        <Box component="section" className={classes.wrapper}>
          <Helmet title={`${tag} | ${title}`} />
          <Container>
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              > 
              <Typography variant="h3" component="h3">
                {tagHeader}
              </Typography>
                <List className="taglist">
                  {postLinks}
                </List>
                <p>
                  <Link to="/latestAtLux/">Back to blog</Link>
                </p>
              </div>
            </div>
          </Container>
        </Box>
      </Layout>
    )
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`